<template>
	<div class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a class="nav__btn nav__btn--prev" @click="goBack">
								<i class="icon icon-close"></i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="login">
						<div class="row">
							<!-- <h1 class="login__logo">
								<img src="@/assets/images/logo.png" alt="H_SALAD">
							</h1> -->
							<!-- [D] 로그인 정보 입력 폼 -->
							<ul class="login__ipts">
								<!-- [D] 아이디 입력 전 -->
								<li>
									<i class="icon" 
										:class="idFocus ?
											'icon-member--point2' : 
											'icon-member--light'"
									></i>
									<input 
										type="email" 
										class="ipt-login" 
										@focus="onFocusId(true)" 
										@blur="onFocusId(false)" 
										:placeholder="t('10771')"
										v-model="id"
										>  <!--재번역필요-->
								</li>
								<li>
									<i class="icon" 
										:class="passwordFocus ? 
											'icon-lock--point' : 
											'icon-lock'"
									></i>
									<input 
										:type="showPassword ? 'text' : 'password'"
										class="ipt-login" 
										@focus="onFocusPassword(true)" 
										@blur="onFocusPassword(false)" 
										:placeholder="t('10772')"
										v-model="password"
										><!--재번역필요-->
									<button v-if="password.length > 0" type="button" class="btn-frmview" @click="onClickShowPassword">
										<i class="icon icon-view" :class="{'disabled': !showPassword}"></i>
									</button>
								</li>
							</ul>
							<!-- [D] 로그인 버튼 -->
							<div class="login__button">
								<button type="button" class="btn" :class="password.length > 0 && id.length > 0 ? 'btn-confirm' : 'btn-disabled'" @click="onClickLogin">
									{{t('10685')}}
								</button>
							</div>
							<!-- [D] 로그인 메뉴 -->
							<div class="login__menus">
								<router-link to="/member/find/id/step1">{{t('10231')}}</router-link >
								<span class="login__dot"></span>
								<router-link to="/member/find/password/step1">{{t('10229')}}</router-link >
								<span class="login__dot"></span>
								<router-link to="/join/step1">{{t('10678')}}</router-link >
							</div>
							<!-- 로그인 SNS메뉴 -->
							<div class="login__sns">
								<ul class="sns">
									<li>
										<a @click="onClickKakaoLogin">
											<i class="icon icon-sns icon-kt"></i>
											<span class="sns__title">
												{{t('10425')}} 
											</span>
										</a>
										<i class="icon icon-right icon-right--light"></i>
									</li>
									<li>
										<div id="naverIdLogin" style="display:none" ref="naverLoginButton"/>
										<a @click="onClickNaverLogin">
											<i class="icon icon-sns icon-naver"></i>
											<span class="sns__title">
												{{t('10428')}}
											</span>
										</a>
										<i class="icon icon-right icon-right--light"></i>
									</li>
									<li v-if="isIOS">
										<div id="appleid-signin" style="display:none" ref="appleLoginButton"/>
										<a @click="onClickAppleLogin">
											<i class="icon icon-sns icon-apple"></i>
											<span class="sns__title">
												{{t('10429')}}
											</span>
										</a>
										<i class="icon icon-right icon-right--light"></i>
									</li>
								</ul>
							</div>
							<!-- 비회원 예약 조회 -->
							<div class="login__guest">
								<router-link to="/guest">{{t('10082')}}</router-link >
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore} from "vuex"
import { onMounted, onUnmounted, computed, ref } from "vue"
import uuidv4 from "@/utils/uuid";
import iOS from "@/utils/iOS";
import jwt_decode from "jwt-decode";
import { kakaoCallbackUrl, naverCallbackUrl, naverClientId, appleCallbackUrl, appleClientId } from "@/utils/OAuthLogin";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup: function(){
		const store = useStore();
		const router = useRouter();
		const sessionId = computed(() => store.state.sessionId);
		const fcmToken = ""
		const id = ref("");
		const idFocus = ref(false);
		const openErrorAlert = ref(false)
		const isIOS = ref(iOS());

		const userData = computed(() => store.state.userData);
		const password = ref("");
		const showPassword = ref(false);
		const passwordFocus = ref(false);

		const naverLoginButton = ref(null);

		const appleLoginButton = ref(null);
		const { t }= useI18n() 

    	onMounted(() => {
			
	    try {
        	store.dispatch('app/requestFCMappToken');
        	window.receivedFCMappToken = function receivedFCMappToken(token) {
				store.commit("SET_USERDATA", {userData: {
					...userData.value,
					fcmToken: token.message["token"]
				}});
        	};
		} catch(e) {
       		console.log(e)
      	}
        	 
    	});

		let ipAddr = "";
		const getIp = async () => {
			ipAddr = await fetch('https://api64.ipify.org').then(res=>res.text());
		}
		const eventAppleIDSignInOnSuccess = (data) => {
			const appleData = jwt_decode(data.detail.authorization.id_token);
			
			const login = async (email, id) =>{
				if(ipAddr.length === 0){
					await getIp();
				}
				
				store.commit("SET_USERDATA", {userData: {
					...userData.value,
					ios_email: email,
					sns_ci: id,
				}});
				store.dispatch("mmb01/fetchMmb01", {
					mem_id: email,
					sessionid: sessionId.value,
					ip_addr: ipAddr,
					sns_join_flag: "03",
					sns_ci: id,
					fcm_token:userData.value.fcmToken,
				}).then(x => {
          			if(x.Res_cd === "-1"){
          			  store.commit("mmb01/SET_SNS_INFO", { email, snsJoinFlag: "03", snsCi: id, snsNickname: null, snsPhoneNumber: null });
          			  router.replace("/join/step1");
          			}
          			else if(x.Res_cd === "0000" && x.Res_data.length > 0){
          			  store.commit("SET_USERDATA", {userData: x.Res_data[0]})
          			  router.replace("/");
          			}
				});
			}

			login(appleData.email, appleData.sub);
		};

		const eventAppleIDSignInOnFailure = (error) => {
			console.log(error);
		};

		onMounted(() => {
			const naverLogin = new window.naver.LoginWithNaverId({
				clientId: naverClientId,
				callbackUrl: naverCallbackUrl,
    			loginButton: {color: "green", type: 2, height: 40}
			});
			naverLogin.init();

			window.AppleID.auth.init({
				clientId : appleClientId,
				scope : 'name email',
				redirectURI: appleCallbackUrl,
				state : createState(),
						usePopup: true
			});

			document.addEventListener('AppleIDSignInOnSuccess', eventAppleIDSignInOnSuccess);
			document.addEventListener('AppleIDSignInOnFailure', eventAppleIDSignInOnFailure);
		});

		onUnmounted(() =>{
			document.removeEventListener('AppleIDSignInOnSuccess', eventAppleIDSignInOnSuccess);
			document.removeEventListener('AppleIDSignInOnFailure', eventAppleIDSignInOnFailure);
		});
	
		const createState = () => {
			const state = uuidv4();
			localStorage.setItem("state", state);

			return state;
		}

		const onFocusId = (isFocus) =>{
			idFocus.value = isFocus;
		}

		const onFocusPassword = (isFocus) =>{
			passwordFocus.value = isFocus;
		}

		const onClickShowPassword = () =>{
			showPassword.value = !showPassword.value;
		}

		const onClickAppleLogin = () => {
			appleLoginButton.value.firstChild.click();
		}

		const onClickNaverLogin = () => {
			naverLoginButton.value.firstChild.click();
		}

		const onClickKakaoLogin = () => {
			 window.Kakao.Auth.authorize({
      		 redirectUri: kakaoCallbackUrl,
    	});
		};

		const onClickLogin = () => {
			if(ipAddr.length === 0){
				return;
			}

			store.dispatch("mmb01/fetchMmb01", {
				mem_id: id.value,
				passwd: password.value,
				sessionid: sessionId.value,
				ip_addr: ipAddr,
				fcm_token:userData.value.fcmToken,
			}).then(x=>{
				//console.log(x);
				if(x.Res_cd === "-1"){
					//alert("아이디 또는 비밀번호가 올바르지 않습니다. 다시 입력해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "아이디 또는 비밀번호가 올바르지 않습니다. 다시 입력해주세요."});
					openErrorAlert.value = true;
				}
				else if(x.Res_cd === "0000" && x.Res_data.length > 0){
					store.commit("SET_USERDATA", {userData: x.Res_data[0]});
					router.replace("/");
				}
			});
		}

		const goBack = () => {
    		if(userData.value.mem_id === undefined){
    		    store.commit("SET_USERDATA", {userData: {}});
				store.commit('mhm01/RESET_ALL');
    		}
			router.go(-1);
		}

		getIp();

		return {
			onClickLogin,
			id,
			idFocus,
			onFocusId,
			password,
			showPassword,
			passwordFocus,
			onFocusPassword,
			onClickShowPassword,
			goBack,
			onClickKakaoLogin,
			onClickAppleLogin,
			naverLoginButton,
			onClickNaverLogin,
			appleLoginButton,
			isIOS,
			fcmToken,
			openErrorAlert,
			t,  //번역필수 모듈
		  i18n, 
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
		}
	}
}
</script>